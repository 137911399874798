import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const ChangeEmailModal = ({ domain, show, handleClose, currentEmail }) => {
  const [email, setEmail] = useState("");
  const [verificationStep, setVerificationStep] = useState(0);
  const [verificationCode, setVerificationCode] = useState("");
  const [sendVerificationCodeText, setSendVerificationCodeText] = useState(
    "Send Verification Code <i class='fas fa-arrow-right'></i>"
  );
  const [isSendingVerification, setIsSendingVerification] = useState(false);
  const [
    authenticateVerificationCodeText,
    setAuthenticateVerificationCodeText,
  ] = useState("Proceed <i class='fas fa-arrow-right'></i>");
  const [isAuthenticatingVerification, setIsAuthenticatingVerification] =
    useState(false);
  const [authenticationError, setAuthenticationError] = useState(false);

  const sendVerification = () => {
    // Prevent double submission if already in progress
    if (isSendingVerification) return;

    // Set submitting state to true
    setIsSendingVerification(true);

    // Update button text to indicate sending code
    setSendVerificationCodeText(
      "Sending code <i class='fas fa-circle-notch fa-spin'></i>"
    );

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/api/users/sendverification", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // Reset submitting state to false
        setIsSendingVerification(false);
        setAuthenticationError(false);

        // Update button text and proceed to the next step
        setSendVerificationCodeText(
          "Send Verification Code <i class='fas fa-arrow-right'></i>"
        );
        setVerificationStep(1);
      })
      .catch((error) => {
        // Reset submitting state to false in case of error
        setIsSendingVerification(false);
        setAuthenticationError(false);
        setSendVerificationCodeText(
          "Send Verification Code <i class='fas fa-arrow-right'></i>"
        );
        console.error("error", error);
      });
  };

  const submitVerificationCode = () => {
    if (isAuthenticatingVerification) return;

    setIsAuthenticatingVerification(true);

    setAuthenticateVerificationCodeText(
      "Verifying <i class='fas fa-circle-notch fa-spin'></i>"
    );

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      verificationCode: otp.join(""),
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/api/users/authenticateverification", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Authentication failed");
        }
        return response.json();
      })
      .then((data) => {
        //Successfully authenticated, set cookies
        Cookies.set("userId", data.user._id, { expires: 7 });
        Cookies.set("userEmail", data.user.email, { expires: 7 });
        setAuthenticationError(false);
        setIsAuthenticatingVerification(false);
        setAuthenticateVerificationCodeText(
          "Proceed <i class='fas fa-arrow-right'></i>"
        );
        setVerificationStep(2);
      })
      .catch((error) => {
        setAuthenticationError(true);
        // Reset submitting state to false in case of error
        setIsAuthenticatingVerification(false);
        // Update button text and proceed to the next step
        setAuthenticateVerificationCodeText(
          "Proceed <i class='fas fa-arrow-right'></i>"
        );
        console.error("error", error);
        // Handle authentication failure, such as displaying an error message to the user
      });
  };

  const backVerificationStep = () => {
    setVerificationStep(verificationStep - 1);
  };

  const [otp, setOTP] = useState(Array(6).fill("")); // State to hold OTP values
  const inputs = useRef([]);

  const handleInputChange = (index, value) => {
    value = value.toUpperCase();
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
    if (value && index < 5) {
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && !otp[index]) {
      const newOTP = [...otp];
      newOTP[index - 1] = ""; // Clear the previous input value
      setOTP(newOTP);
      inputs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("Text").toUpperCase();
    // Split the pasted text into individual characters
    const pastedChars = pastedText.split("");
    // Update OTP with pasted characters, starting from the first input
    const newOTP = Array(6).fill("");
    pastedChars.forEach((char, index) => {
      if (index < 6) {
        newOTP[index] = char;
      }
    });
    setOTP(newOTP);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Change email address</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {verificationStep == 1 ? (
          <>
            <h3>
              <i className="fas fa-envelope"></i> Verification Code Sent!
            </h3>
            {authenticationError && (
              <div
                className="alert alert-danger"
                role="alert"
              >
                <i className="fas fa-exclamation-circle"></i> Invalid code!
                Please make sure you are entering the latest verification code
                we've sent to your email.
              </div>
            )}
            <p>
              Please check your email ({email}) and enter the 6-character code
              below. Can't find it? Please check your spam folder.{" "}
              <a
                href="#"
                onClick={backVerificationStep}
              >
                Click here to send again
              </a>
            </p>

            {/* <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control"
                id="verificationCode"
                name="verificationCode"
                placeholder="Enter Verification Code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <label htmlFor="verificationCode">Enter Verification Code</label>
            </div> */}

            <div className="inputs d-flex flex-row justify-content-center mt-2">
              {Array.from({ length: 6 }, (_, index) => (
                <input
                  ref={(input) => (inputs.current[index] = input)}
                  key={index}
                  className="m-2 text-center form-control rounded fw-bold text-danger"
                  type="text"
                  maxLength="1"
                  value={otp[index]}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  placeholder="x"
                />
              ))}
            </div>
          </>
        ) : verificationStep === 2 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <b>
              <i
                className="fas fa-check-circle"
                style={{ fontSize: 100, paddingBottom: 20, color: "#fc5c00" }}
              ></i>
            </b>
            <h3>All set!</h3>
            <p style={{ textAlign: "center" }}>
              Thank you for verifying, {email}.
              <br /> You may now resume your activity.
            </p>
          </div>
        ) : (
          <>
            <div
              className="alert alert-warning"
              role="alert"
            >
              <i className="fas fa-info-circle"></i> Your current email is{" "}
              <b>{currentEmail}</b>. Please provide your new email address
              below. We will send you a 6-character code to verify this new
              address.
            </div>
            <div className="form-floating mb-2">
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Your Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="email">Enter new Email address</label>
            </div>

            <p>
              By proceeding, you are accepting our{" "}
              <Link
                className="text-danger"
                to="/termsandconditions"
                target="_blank"
              >
                Terms & Conditions
              </Link>
              .
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {verificationStep == 1 ? (
          <button
            onClick={submitVerificationCode}
            type="button"
            className="btn btn-dark btn-lg"
            dangerouslySetInnerHTML={{
              __html: authenticateVerificationCodeText,
            }}
          ></button>
        ) : verificationStep === 2 ? (
          <button
            onClick={handleClose}
            type="button"
            className="btn btn-dark btn-lg"
          >
            Proceed <i className="fas fa-arrow-right"></i>
          </button>
        ) : (
          <button
            onClick={sendVerification}
            type="button"
            className="btn btn-dark btn-lg"
            dangerouslySetInnerHTML={{ __html: sendVerificationCodeText }}
          ></button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeEmailModal;
