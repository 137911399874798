import React, { useEffect, useState } from "react";
import CountryRibbon from "../components/CountryRibbon";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet-async";

const About = ({ domain }) => {
  return (
    <>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="Bleep is a multimedia application designed to empower consumers to report suspected counterfeit or substandard products."
        />
      </Helmet>

      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "40px",
          paddingBottom: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>
        <div
          className="row d-flex align-items-center"
          style={{
            position: "relative",
          }}
        >
          <div
            className="col d-flex align-items-center"
            style={{ color: "white", padding: "20px", flexDirection: "row" }}
          >
            <Link
              to="/"
              className="text-decoration-none text-white"
            >
              <span
                style={{
                  backgroundColor: "#00000089",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginRight: "10px",
                }}
              >
                <i className="fas fa-chevron-left"></i>
              </span>
            </Link>

            <div>
              <b
                style={{
                  fontSize: "30px",
                  textShadow: "#1c1c1c 1px 2px 0px",
                }}
              >
                About Us
              </b>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          marginBlock: "40px",
          marginInline: "10vw",
          paddingBlock: "20px",
          paddingInline: "20px",
          backgroundColor: "#24120B",
          borderRadius: "10px",
        }}
      >
        <div className="row d-flex align-items-center">
          <div
            className="col"
            style={{ color: "#ffffff" }}
          >
            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              BLEEP is a multimedia application designed to empower consumers to
              report suspected counterfeit or substandard products, enabling
              verification and corrective action by regulators, brands, and
              other market actors.
            </p>

            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              By creating an online community data repository, BLEEP supports
              anti-counterfeit initiatives while promoting authentic brand
              marketing.
            </p>

            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              BLEEP fosters community engagement to uncover the deceptions and
              hidden risks of counterfeit goods, enhancing consumer experiences
              with genuine products.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
