import React, { useEffect, useState } from "react";
import CountryRibbon from "../components/CountryRibbon";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet-async";

const FAQs = ({ domain }) => {
  return (
    <>
      <Helmet>
        <title>FAQs</title>
        <meta
          name="description"
          content="Frequency Asked Questions about Bleep"
        />
      </Helmet>

      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "40px",
          paddingBottom: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>
        <div
          className="row d-flex align-items-center"
          style={{
            position: "relative",
          }}
        >
          <div
            className="col d-flex align-items-center"
            style={{ color: "white", padding: "20px", flexDirection: "row" }}
          >
            <Link
              to="/"
              className="text-decoration-none text-white"
            >
              <span
                style={{
                  backgroundColor: "#00000089",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginRight: "10px",
                }}
              >
                <i className="fas fa-chevron-left"></i>
              </span>
            </Link>

            <div>
              <b
                style={{
                  fontSize: "30px",
                  textShadow: "#1c1c1c 1px 2px 0px",
                }}
              >
                Frequently Asked Questions
              </b>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          marginBlock: "40px",
          marginInline: "10vw",
          paddingBlock: "20px",
          paddingInline: "20px",
          backgroundColor: "#24120B",
          borderRadius: "10px",
        }}
      >
        <div className="row d-flex align-items-center">
          <div
            className="col"
            style={{ color: "#ffffff" }}
          >
            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                1. How do I report a counterfeit product?
              </h3>
              <p>
                To report a counterfeit, click on the "Report" button, fill in
                the required details, and submit your report.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                2. What happens after I submit a report?
              </h3>
              <p>
                Once submitted, your report will be reviewed by the Bleep
                actors. You’ll then receive notifications about the status of
                your report, including verification updates and comments from
                other users.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                3. Can I follow or watch certain reports?
              </h3>
              <p>
                Yes, you can follow reports to stay updated on their progress.
                You’ll receive notifications when there are updates, comments,
                or verifications related to the reports you’re following.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                4. How are suspected counterfeit products verified?
              </h3>
              <p>
                Verification is conducted through a combination of expert
                analysis and collaboration between Bleep actors i.e. Consumers,
                Brands, Regulators and ACN team. Verified reports are clearly
                marked on the platform.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                5. What types of products can I report on Bleep?
              </h3>
              <p>
                You can report any type of product you suspect to be
                counterfeit, in all sectors and categories not limited to
                Pharmaceuticals, Electronics, Organizations, Agriculture,
                Currencies, Government, Media, Beverages, Cosmetics, Education,
                Motor industry, Petroleum, Construction etc.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                6. How does Bleep protect my privacy?
              </h3>
              <p>
                Bleep is committed to safeguarding your privacy. Personal
                information submitted is kept confidential and is only used to
                enhance the verification process. Find out more in our{" "}
                <a
                  href="/termsofservice"
                  style={{ color: "#ff6200" }}
                >
                  Terms of Service.
                </a>
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                7. Can I delete my report after submission?
              </h3>
              <p>
                Yes, you can delete your report within 5 minutes of submission,
                after which it cannot be removed.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                8. What should I do if I encounter a technical issue with Bleep?
              </h3>
              <p>
                If you experience any technical issues, contact our support team
                through the “Contact Us” feature within the app, Social media
                platforms or by emailing our support team on{" "}
                <a
                  href="mailto:info@bleep.ug"
                  style={{ color: "#ff6200" }}
                >
                  info@bleep.ug.
                </a>
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                9. Can I share reports on social media?
              </h3>
              <p>
                Yes, Bleep has a sharing option that allows you to share reports
                directly on social media platforms to raise awareness about
                potential counterfeit products.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                10. How can I get updates on new features and developments?
              </h3>
              <p>
                The Bleep platform is regularly updated to enhance user
                experience. These updates are automatically applied through the
                app stores, so always ensure your app is up-to-date to enjoy the
                best experience.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                11. How can I provide feedback on Bleep?
              </h3>
              <p>
                We value your feedback! Provide feedback through our social
                media pages, website contact form, App stores and email to our
                support team. Your input helps us improve the platform.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                12. How do I disable or suspend my account?
              </h3>
              <p>
                If you wish to disable or suspend your account, please contact
                our support team for assistance.
              </p>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ color: "#ff6200" }}>
                13. How can I learn more about using Bleep?
              </h3>
              <p>
                You can learn more about using Bleep by exploring our user
                guides, attending training sessions, or contacting our support
                team for additional help.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQs;
