import { createRoot } from "react-dom/client";
import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

const root = createRoot(document.getElementById("root"));
const helmetContext = {};
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* Helmet for rendering meta tags*/}
      <HelmetProvider context={helmetContext}>
        <App />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);
