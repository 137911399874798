import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const ChangeDisplayNameModal = ({
  domain,
  show,
  handleClose,
  userData,
  saveDisplayName,
}) => {
  const userId = userData[0];
  const userType = userData[1];
  const userName = userData[2];
  const userEmail = userData[3];
  const userPassword = userData[4];
  const userOrg = userData[5];
  const userPermissions = userData[6];
  const userAnonymous = userData[7];

  const [userDisplayName, setUserDisplayName] = useState(undefined);
  const [userAnonymousName, setUserAnonymousName] = useState(undefined);
  const [displayNameType, setDisplayNameType] = useState(null);

  useEffect(() => {
    if (userId !== undefined) {
      setUserAnonymousName("Bleeper#" + userId.slice(-6));
    }

    if (userAnonymous === "true") {
      setDisplayNameType("anonymous");
    } else {
      setDisplayNameType("personalized");
    }
    setUserDisplayName(userName);
  }, [userId]);

  const handleRadioChange = (e) => {
    setDisplayNameType(e.target.value);
  };

  const setDisplayName = () => {
    let anonymous = false;
    if (displayNameType === "personalized") {
      anonymous = false;
    } else {
      anonymous = true;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      username: userDisplayName,
      anonymous: anonymous,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`/api/users/displayname/${userId}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        Cookies.set("userAnonymous", anonymous);
        Cookies.set("userName", userDisplayName);
        saveDisplayName();
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Change your display name</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your display name is the name you will be publicly identified by on
          your Bleeps, comments, and other user activity.
        </p>
        <div
          style={{
            backgroundColor: "#fdfaf8",
            padding: 20,
            borderRadius: 20,
            marginBottom: 20,
          }}
        >
          <b>Choose Display Name Type</b>
          <div>
            <input
              type="radio"
              id="personalized"
              name="displayNameType"
              value="personalized"
              checked={displayNameType === "personalized"}
              onChange={handleRadioChange}
            />
            <label htmlFor="personalized">
              &nbsp;Personalized Display Name
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="anonymous"
              name="displayNameType"
              value="anonymous"
              checked={displayNameType === "anonymous"}
              onChange={handleRadioChange}
            />
            <label htmlFor="anonymous">&nbsp;Anonymous</label>
          </div>
        </div>
        {displayNameType === "anonymous" && (
          <div
            style={{
              backgroundColor: "#ffe2d2",
              border: "2px solid #ff5e00",
              padding: 20,
              borderRadius: 20,
              marginBottom: 20,
            }}
          >
            <b>
              <i className="fas fa-user-secret"></i> Anonymous
            </b>
            <p>
              You can maintain anonymity by using this anonymous display name
            </p>
            <h3
              style={{
                letterSpacing: 10,
              }}
            >
              {userAnonymousName}
            </h3>
          </div>
        )}
        {displayNameType === "personalized" && (
          <div
            style={{
              backgroundColor: "#ffe2d2",
              border: "2px solid #ff5e00",
              padding: 20,
              borderRadius: 20,
              marginBottom: 20,
            }}
          >
            <b>Personalized Display Name</b>
            <p>
              Fill in your name/nickname as you would like to appear to others
            </p>
            <input
              className="form-control form-control-lg"
              type="text"
              name="username"
              placeholder="Enter your display name here"
              value={userDisplayName}
              onChange={(e) => setUserDisplayName(e.target.value)}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={setDisplayName}
          type="button"
          className="btn btn-dark btn-lg"
        >
          Save <i className="fas fa-check"></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeDisplayNameModal;
