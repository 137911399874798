import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";

const ConfirmModal = ({ show, handleClose, handleProceed, confirmMessage }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Please confirm</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{confirmMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-danger btn-lg"
          onClick={handleProceed}
        >
          Yes, proceed <i className="fas fa-arrow-right"></i>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
