import React, { useEffect, useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";
import lottie from "lottie-web";
import thumbsupanimation from "./lottie/thumbsup.json";
import citiesData from "./misc/cities.json";

const ReportForm = ({
  domain,
  show,
  handleClose,
  handleFormSubmit,
  handleSaveAndShowEmailAuthModal,
}) => {
  const [selectedImages, setSelectedImages] = useState(new Array(8).fill(null));
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [submissionProgress, setSubmissionProgress] = useState(0);
  const [currency, setCurrency] = useState("UGX");
  // City input
  const [selectionMade, setSelectionMade] = useState(false);
  const [cityInput, setCityInput] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  // set currency based on domain
  useEffect(() => {
    if (domain === "ke") {
      setCurrency("KES");
    } else if (domain === "ug") {
      setCurrency("UGX");
    }
  }, [domain]);

  // Load cities data from JSON file
  useEffect(() => {
    setFilteredCities(citiesData);
  }, []);

  // Filter cities based on user input
  const handleCityInputChange = (event) => {
    const inputValue = event.target.value.trim().toLowerCase();
    if (inputValue !== "") {
      const filtered = citiesData
        .filter((city) => city.City.toLowerCase().includes(inputValue))
        .slice(0, 3); // Limit to maximum of 3 matching cities
      setFilteredCities(filtered);
    } else {
      setFilteredCities([]);
    }
    setCityInput(inputValue);
    // Reset selectionMade state when input changes
    setSelectionMade(false);
  };

  // Handle selection of a city from the dropdown
  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setCityInput(city.City);
    setSelectionMade(true);
  };

  // Validate selected city
  const isValidCity = () => {
    return selectedCity !== null;
  };

  const [formData, setFormData] = useState({
    description: "",
    address: "",
    price: "",
    category: "",
  });
  const userId = Cookies.get("userId");
  const userEmail = Cookies.get("userEmail");

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Check if the input being changed is for the "price" field
    if (name === "price") {
      // Remove non-numeric characters from input value
      const numericValue = value.replace(/\D/g, "");

      // Format currency with commas
      const formattedValue = numericValue
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // Update the state with the formatted value
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedValue,
      }));
    } else {
      // For other fields, update the state without formatting
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const prompts = [
    "Product photo",
    "Product packaging",
    "Product barcode",
    "Batch No. & lot print",
    "Product label",
    "Additional photo 1",
    "Additional photo 2",
    "Additional photo 3",
  ];

  const handleFileChange = (index, event) => {
    const files = event.target.files;

    if (files.length > 0) {
      const newSelectedImages = [...selectedImages];
      const newFile = new File([files[0]], files[0].name, {
        type: files[0].type,
      }); // Create a new File object
      newSelectedImages[index] = newFile;
      setSelectedImages(newSelectedImages);
    }
  };

  const handleDeleteImage = (index) => {
    const newSelectedImages = [...selectedImages];
    newSelectedImages[index] = null;
    setSelectedImages(newSelectedImages);

    // Clear the file input value
    const fileInput = document.getElementById(`fileInput${index}`);
    if (fileInput) {
      fileInput.value = ""; // Clear the value to allow re-uploading the same file
    }
  };

  const postReport = (event) => {
    event.preventDefault();

    // Check if all fields are filled
    const description = event.target.description.value;
    const address = event.target.address.value;
    const price = event.target.price.value;
    const category = event.target.category.value;
    const country = event.target.country.value;

    let atLeastOneImageSelected = false;

    for (let i = 0; i < 8; i++) {
      if (selectedImages[i] !== null) {
        atLeastOneImageSelected = true;
        break;
      }
    }

    if (!atLeastOneImageSelected) {
      // If none of the images are selected, display an error message
      alert("Please upload at least one photo of your item.");
      return;
    }

    if (
      !description ||
      !address ||
      !selectedCity ||
      !price ||
      !category ||
      !country
    ) {
      // If any field is empty, display an error message or handle it accordingly
      alert("Please fill in all fields.");
      return;
    }

    setSubmissionInProgress(true);

    const formData = new FormData();

    // Append each selected image to the formData object
    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("postimages", selectedImages[i]);
    }

    // Append other form data fields
    formData.append("user", userId);
    formData.append("description", description);
    formData.append("address", address);
    formData.append("city", selectedCity.City);
    formData.append("price", price);
    formData.append("category", category);
    formData.append("country", country);

    fetch("/api/posts", {
      method: "POST",
      body: formData,
      onUploadProgress: (progressEvent) => {
        console.log(progressEvent); // Log the event object for debugging
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(progress);
        setSubmissionProgress(progress);
      },
    })
      .then((response) => response.json())
      .then((data) => {
        handleFormSubmit();
        setSubmissionInProgress(false);
        setSubmissionProgress(0);
        setSelectedImages([]);
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
        setSubmissionInProgress(false);
        setSubmissionProgress(0);
      });
  };

  const lottiecontainer = useRef(null);

  useEffect(() => {
    // Load and render the animation
    if (lottiecontainer.current) {
      lottie.loadAnimation({
        container: lottiecontainer.current,
        renderer: "canvas", // You can choose the renderer: 'svg', 'canvas', 'html'
        loop: true,
        autoplay: true,
        animationData: thumbsupanimation, // Pass your animation data here
      });
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{
        backdropFilter: "blur(2px)",
      }}
    >
      <Modal.Header
        style={{
          // borderRadius: "50px",
          backgroundColor: "#FCF1E6",
        }}
        closeButton
      >
        <Modal.Title>
          <b>Submit a Bleep Report</b>
        </Modal.Title>
      </Modal.Header>
      <form
        onSubmit={postReport}
        encType="multipart/form-data"
      >
        <Modal.Body
          style={{
            backgroundColor: "#FCF1E6",
          }}
        >
          <div
            style={{
              backgroundImage:
                "linear-gradient(120deg, #ffc400 0%, #ff3700 100%)",
              borderRadius: "10px",
              padding: "10px",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            <span>
              Report suspected counterfeit goods/ services to the Bleep
              community!
            </span>
          </div>

          {/* <div
            ref={lottiecontainer}
            style={{ width: "100%", height: "100%" }}
          ></div> */}

          <span className="fw-bold">1) Product category</span>
          <select
            className="form-select mb-2"
            aria-label="Default select example"
            id="category"
            name="category"
            value={formData.category}
            onChange={handleInputChange}
          >
            <option selected>Select category</option>
            <option value="Agriculture">Agriculture</option>
            <option value="Apparel">Apparel</option>
            <option value="Automobiles">Automobiles</option>
            <option value="Beverages">Beverages</option>
            <option value="Construction">Construction</option>
            <option value="Cosmetics">Cosmetics</option>
            <option value="Currencies">Currencies</option>
            <option value="Education">Education</option>
            <option value="Electronics">Electronics</option>
            <option value="Food">Food</option>
            <option value="Government">Government</option>
            <option value="Media">Media</option>
            <option value="Organizations">Organizations</option>
            <option value="Petroleum">Petroleum</option>
            <option value="Pharmaceuticals">Pharmaceuticals</option>
          </select>

          <span className="fw-bold">2) Photos</span>
          <p>
            You may upload up to 8 photos of your item, please follow the
            prompts below.
          </p>
          {/* <ul>
            <li>Photo of the item</li>
            <li>Photo of the packaging from different angles</li>
            <li>Photo of the receipt</li>
            <li>Comparative photos of the original and counterfeit</li>
            <li>Any other photos that would be relevant for upload</li>
          </ul> */}
          <div className="d-flex flex-wrap">
            {selectedImages.map((image, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-center clickable"
                style={{
                  width: "100px",
                  aspectRatio: "1",
                  borderRadius: "30px",
                  backgroundColor: "rgba(255, 227, 204, 1)",
                  margin: "5px",
                  border: "3px dashed rgb(255, 102, 0)",
                  position: "relative",
                }}
                onClick={() =>
                  document.getElementById(`fileInput${index}`).click()
                }
              >
                {image ? (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "30px",
                      }}
                    />
                    <button
                      className="btn btn-light btn-sm"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        height: "30px",
                        width: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "100px",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteImage(index);
                      }}
                    >
                      <i className="fas fa-times-circle"></i>
                    </button>
                  </div>
                ) : (
                  <div
                    className="col d-flex flex-column justify-content-center align-items-center"
                    style={{
                      fontSize: "15px",
                      textAlign: "center",
                      lineHeight: "1",
                    }}
                  >
                    <i
                      className="fas fa-upload"
                      style={{
                        paddingBottom: "5px",
                      }}
                    ></i>
                    <span>{prompts[index]}</span>
                  </div>
                )}
                <input
                  id={`fileInput${index}`}
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  style={{ display: "none" }}
                  onChange={(event) => handleFileChange(index, event)}
                />
              </div>
            ))}
          </div>

          <span className="fw-bold">
            3) What is suspicious about the good(s) or services?
          </span>
          <div className="mb-2">
            <textarea
              className="form-control"
              placeholder="Describe the problem with the item(s) here"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              style={{ height: "100px" }}
            ></textarea>
          </div>

          <span className="fw-bold">4) Where did you get this item from?</span>
          <div className="mb-2">
            <textarea
              className="form-control"
              placeholder="Purchase address"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              style={{ height: "100px" }}
            ></textarea>
          </div>

          <div className="mb-2">
            <input
              type="text"
              className="form-control"
              value={cityInput}
              onChange={handleCityInputChange}
              placeholder="City / Town"
            />
            {cityInput !== "" && !selectionMade && (
              <ul className="list-group">
                {filteredCities.map((city) => (
                  <li
                    className="list-group-item"
                    key={city.City}
                    onClick={() => handleCitySelect(city)}
                  >
                    {city.City}
                  </li>
                ))}
              </ul>
            )}
            {isValidCity() && (
              <p>
                Selected city: <b>{selectedCity.City}</b>{" "}
                <i className="fas fa-map-marker-alt"></i>
              </p>
            )}
            {!isValidCity() && cityInput !== "" && (
              <p>Please select a city from above.</p>
            )}
          </div>

          <span className="fw-bold">
            5) How much did you pay for this item?
          </span>
          <div className="input-group mb-2">
            <input
              type="text"
              className="form-control"
              id="price"
              name="price"
              placeholder="Amount"
              value={formData.price}
              onChange={handleInputChange}
            />
            <span className="input-group-text">{currency}</span>
          </div>

          <input
            id="country"
            name="country"
            type="hidden"
            value={domain}
          />
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#FCF1E6",
          }}
        >
          {submissionInProgress && (
            <div
              className="progress"
              style={{ width: "100%" }}
            >
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow={submissionProgress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${submissionProgress}%` }}
              >
                {submissionProgress}%
              </div>
            </div>
          )}
          {userId ? (
            <button
              type="submit"
              className="btn btn-warning btn-lg"
              disabled={submissionInProgress}
            >
              {submissionInProgress ? "Posting..." : "Post report "}
              {!submissionInProgress && <i className="fas fa-arrow-right"></i>}
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-dark btn-lg"
              onClick={handleSaveAndShowEmailAuthModal}
            >
              Bleep <i className="fas fa-arrow-right"></i>
            </button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ReportForm;
