import React, { useEffect, useState } from "react";
import CountryRibbon from "../components/CountryRibbon";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet-async";

const TermsOfService = ({ domain }) => {
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    // Fetch markdown content from the file
    fetch("/tocs/v1.md")
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text))
      .catch((error) => console.error("Error fetching markdown:", error));
  }, []);

  return (
    <>
      <Helmet>
        <title>Terms of Service | BLEEP</title>
        <meta
          name="description"
          content="By using Bleep, you are subject to, and are agreeing to the
              following terms of service."
        />
      </Helmet>

      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "40px",
          paddingBottom: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>
        <div
          className="row d-flex align-items-center"
          style={{
            position: "relative",
          }}
        >
          <div
            className="col d-flex align-items-center"
            style={{ color: "white", padding: "20px", flexDirection: "row" }}
          >
            <Link
              to="/"
              className="text-decoration-none text-white"
            >
              <span
                style={{
                  backgroundColor: "#00000089",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginRight: "10px",
                }}
              >
                <i className="fas fa-chevron-left"></i>
              </span>
            </Link>

            <div>
              <b
                style={{
                  fontSize: "30px",
                  textShadow: "#1c1c1c 1px 2px 0px",
                }}
              >
                Terms of Service
              </b>
              <p
                style={{
                  textShadow: "#1c1c1c 1px 1px 0px",
                }}
              >
                By using Bleep, you are subject to, and are agreeing to the
                following terms of service.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          marginBlock: "40px",
          marginInline: "10vw",
          paddingBlock: "20px",
          paddingInline: "20px",
          backgroundColor: "#24120B",
          borderRadius: "10px",
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <div className="row d-flex align-items-center">
          <div
            className="col"
            style={{ color: "#ffffff" }}
          >
            <ReactMarkdown>{markdownContent}</ReactMarkdown>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
