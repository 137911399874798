import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";

const Post = ({ post, showEmailAuthModal }) => {
  const navigate = useNavigate();

  const userId = Cookies.get("userId");
  const userEmail = Cookies.get("userEmail");

  const avatar = React.useMemo(() => {
    return createAvatar(identicon, {
      seed: post.displayname,
      backgroundType: ["gradientLinear", "solid"],
    }).toDataUri();
  }, []);

  const formattedDateTime = new Date(post.dateTime).toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return (
    <a
      style={{
        textDecoration: "none",
      }}
      href={`/post/${post._id}`}
    >
      <div className="col pb-3">
        <div className="bleep-card card">
          <div
            className="row align-items-center"
            style={styles.row}
          >
            <div
              className="col-10"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  backgroundColor: "#ffffff",
                  marginRight: "10px",
                }}
              >
                <img
                  src={avatar}
                  alt="Avatar"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
              <div>
                <span
                  className="card-title fw-bold"
                  style={styles.title}
                >
                  {post.displayname}
                </span>
                <br />
                <span style={styles.date}>{formattedDateTime}</span>
              </div>
            </div>
            <div
              align="right"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                padding: "10px",
                paddingTop: "15px",
              }}
            >
              {post.status === "Pending action" ? (
                <div className="status status-mini noaction">
                  <span className="text-end">
                    <i className="far fa-clock" />
                  </span>
                </div>
              ) : post.status === "Ongoing action" ? (
                <div className="status status-mini action">
                  <span className="text-end">
                    <i className="fas fa-hourglass-half spin" />
                  </span>
                </div>
              ) : post.status === "Resolved" ? (
                <div className="status status-mini resolved">
                  <span className="text-end">
                    <i className="far fa-check-circle" />
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div>
            <div
              style={{
                position: "absolute",
                top: 230,
                left: 20,
                zIndex: 100,
                display: "flex",
                flexDirection: "row",
                gap: 5,
              }}
            >
              {post.audio && (
                <div
                  style={{
                    backgroundColor: "#ff8c00",
                    borderRadius: 10,
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                  }}
                >
                  <i className="fas fa-microphone"></i>
                </div>
              )}
              {post.video && (
                <div
                  style={{
                    backgroundColor: "#ffd900",
                    borderRadius: 10,
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                  }}
                >
                  <i className="fas fa-clapperboard"></i>
                </div>
              )}
            </div>

            {post.imageUrls.length > 1 ? (
              <div
                style={{
                  display: "flex",
                  paddingInline: 10,
                  paddingBottom: 10,
                }}
              >
                {/* Large image on the left */}
                {post.imageUrls.length > 0 && (
                  <div style={{ flex: 1.5 }}>
                    <img
                      src={post.imageUrls[0]}
                      alt="Post"
                      style={{
                        width: "100%",
                        height: "210px",
                        borderRadius: "10px",
                        backgroundColor: "#7f7f7f",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}

                <div
                  style={{
                    marginLeft: "10px",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  {/* Right column with stacked images */}
                  {post.imageUrls.slice(1, 3).map((item, index) => (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        position: "relative",
                        marginBottom: index === 0 ? "10px" : "0",
                      }}
                    >
                      <img
                        src={item}
                        alt={`Post ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100px",
                          borderRadius: "10px",
                          backgroundColor: "#7f7f7f",
                          objectFit: "cover",
                        }}
                      />

                      {index === 1 && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                            backgroundColor: "rgba(0, 0, 0, 0.36)",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <i
                            className="fas fa-chevron-right"
                            style={{
                              color: "white",
                              fontSize: "20px",
                              marginBottom: "5px",
                            }}
                          ></i>
                          <p
                            style={{
                              color: "white",
                              fontFamily: "Montserrat, sans-serif",
                              fontSize: "12px",
                              lineHeight: "12px",
                              margin: 0,
                            }}
                          >
                            See more
                          </p>
                        </div>
                      )}
                    </div>
                  ))}

                  {/* View if there is no third image */}
                  {post.imageUrls.length < 3 && (
                    <div style={{ width: "100%", position: "relative" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "100px",
                          borderRadius: "10px",
                          backgroundColor: "rgba(0, 0, 0, 0.36)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="fas fa-chevron-right"
                          style={{
                            color: "white",
                            fontSize: "20px",
                            marginBottom: "5px",
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{
                  paddingInline: 10,
                  paddingBottom: 10,
                }}
              >
                <img
                  src={post.imageUrls[0]}
                  alt="Post"
                  style={{
                    width: "100%",
                    height: "210px",
                    borderRadius: "10px",
                    backgroundColor: "#7f7f7f",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}
          </div>
          <div className="card-body">
            <div
              className="flex-row d-flex align-items-center justify-content-between"
              style={styles.actions}
            >
              <div>
                <span>
                  <i className="far fa-thumbs-up"></i>&nbsp;
                  {post.likes} &nbsp;
                  <i className="far fa-thumbs-down"></i>&nbsp;
                  {post.dislikes}
                </span>
              </div>
              <span>
                <i className="far fa-comments"></i>&nbsp; {post.comments}
              </span>
            </div>
            <p
              className="card-text"
              style={styles.description}
            >
              {post.description.length > 120 ? (
                <>
                  {post.description.slice(0, 120)} ...
                  <b>READ MORE</b>
                </>
              ) : (
                post.description
              )}
            </p>
          </div>
        </div>
      </div>
    </a>
  );
};

const styles = {
  row: {
    paddingBottom: "10px",
    color: "white",
    padding: "10px",
    lineHeight: "10px",
  },
  title: {
    fontSize: "0.8em",
  },
  date: {
    fontSize: "0.5em",
  },
  actions: {
    paddingBottom: "20px",
  },
  description: {
    fontSize: "0.8em",
    lineHeight: "1.2em",
    height: "80px",
  },
};

export default Post;
