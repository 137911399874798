import React from "react";
import { ResponsiveCalendar } from "@nivo/calendar";

const PostsPerDayChart = ({ data }) => {
  // Get the current year dynamically
  const currentYear = new Date().getFullYear();

  // Filter the data to include only entries for the current year
  const filteredData = data.filter(
    (item) => new Date(item.date).getFullYear() === currentYear
  );

  // Prepare data for calendar chart
  const formattedData = filteredData.map((item) => ({
    day: item.date,
    value: item.count,
  }));

  const formatTooltip = (value) => `${value} bleep${value > 1 ? "s" : ""}`;

  return (
    <div style={{ height: "300px" }}>
      <ResponsiveCalendar
        data={formattedData}
        from={`${currentYear}-01-02`}
        to={`${currentYear}-12-31`}
        emptyColor="#301500"
        colors={["#ffe4b2", "#ffc04c", "#ffa500", "#ff8c00", "#ff7f50"]}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        yearSpacing={40}
        monthBorderColor="#000000"
        dayBorderWidth={2}
        dayBorderColor="#301c00"
        legends={[
          {
            anchor: "bottom-right",
            direction: "row",
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: "right-to-left",
          },
        ]}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: "#ebedf0",
              },
              text: {
                fill: "#ebedf0",
              },
            },
          },
        }}
        tooltip={({ day, value }) => (
          <div
            style={{
              background: "#b34700",
              padding: "10px",
              border: "1px solid #181818",
              color: "#ebedf0",
            }}
          >
            <strong>{day}</strong>
            <div>{formatTooltip(value)}</div>
          </div>
        )}
      />
    </div>
  );
};

export default PostsPerDayChart;
