import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

const Verify = ({ domain }) => {
  const [cyphemeLoaded, setCyphemeLoaded] = useState(false);

  useEffect(() => {
    const cyphemeiframe = document.getElementById("cyphemeiframe");
    if (cyphemeiframe) {
      cyphemeiframe.innerHTML = "";
    }

    // Check if the script already exists
    if (!document.getElementById("cyphemeScript")) {
      function CyphemeClient(e, t) {
        this.apiKey = e || "";
        this.options = {};
        const n = {
          cid: "customer_id",
          bgcolor: "background_color",
          textcolor: "text_color",
          iconcolor: "icon_color",
          exstyle: "exstyle",
        };
        for (const o in n) {
          const i = n[o];
          this.options[o] = t && t[i];
        }
      }

      CyphemeClient.prototype.createIframe = function () {
        const src = this.constructIframeSrc();
        const iframe = document.createElement("iframe");
        iframe.src = src;
        iframe.allow = "geolocation *;";
        iframe.title = "Verify product";
        iframe.width = "390";
        iframe.height = "700";
        let style =
          "margin: 0 auto; display: block; border: none; padding: 20px; ";
        if (this.options.exstyle) {
          style += this.options.exstyle;
        }
        iframe.style = style;
        this.insertIframe(iframe);
        setCyphemeLoaded(true);
      };

      CyphemeClient.prototype.constructIframeSrc = function () {
        let src = "https://app.cypheme.com/#/?";
        if (this.apiKey) src += "cid=" + this.apiKey;
        if (this.options.bgcolor) src += "&bgcolor=" + this.options.bgcolor;
        if (this.options.cid) src += "&cid=" + this.options.cid;
        if (this.options.textcolor)
          src += "&textcolor=" + this.options.textcolor;
        if (this.options.iconcolor)
          src += "&iconcolor=" + this.options.iconcolor;
        return src;
      };

      CyphemeClient.prototype.insertIframe = function (iframe) {
        const container = document.getElementById("cyphemeiframe");
        if (container) {
          container.appendChild(iframe);
        } else {
          console.log("Container for iframe not found");
        }
      };

      CyphemeClient.prototype.checkAndSetViewport = function () {
        let viewportMeta = document.querySelector("meta[name='viewport']");
        if (viewportMeta) {
          console.log("viewport meta already existed");
        } else {
          viewportMeta = document.createElement("meta");
          viewportMeta.name = "viewport";
          viewportMeta.content = "width=device-width, initial-scale=1.0";
          const head = document.querySelector("head");
          if (head) {
            head.appendChild(viewportMeta);
          } else {
            console.log("cannot find <head>, cannot add viewport.");
          }
        }
      };

      const client = new CyphemeClient("86", {
        customer_id: "86",
        background_color: "e74f03",
        text_color: "ffffff",
        icon_color: "000000",
      });

      client.createIframe();
      client.checkAndSetViewport();
    }

    // Cleanup function to remove the script tag if necessary
    return () => {
      const script = document.getElementById("cyphemeScript");
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Verify | BLEEP</title>
      </Helmet>

      {/* Cypheme iframe */}
      <div id="cyphemeiframe">
        {/* {!cyphemeLoaded && (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <i
            className="fas fa-circle-notch fa-spin"
            style={{ fontSize: 80, color: "#e67128" }}
          ></i>
        </div>
      )} */}
      </div>
    </>
  );
};

export default Verify;
