import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ domain, userData, signOut }) => {
  return (
    <div className="bleep-footer">
      <footer className="py-3">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item">
            <Link
              to="/"
              className="nav-link px-2 text-body-secondary"
              style={{ marginRight: "20px" }}
            >
              <a className="footer-btn">Home</a>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/about"
              className="nav-link px-2 text-body-secondary"
              style={{ marginRight: "20px" }}
            >
              <a className="footer-btn">About</a>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/faqs"
              className="nav-link px-2 text-body-secondary"
              style={{ marginRight: "20px" }}
            >
              <a className="footer-btn">FAQs</a>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact"
              className="nav-link px-2 text-body-secondary"
              style={{ marginRight: "20px" }}
            >
              <a className="footer-btn">Contact Us</a>
            </Link>
          </li>
        </ul>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: "20px",
          }}
        >
          <Link
            to="/"
            className="text-decoration-none"
          >
            <img
              src="/images/bleeplogo_dark.png"
              style={{
                width: "100px",
                transition: "opacity 0.5s ease",
              }}
              alt=""
            />
          </Link>
        </div>
        <p className="text-center text-white">
          &copy;
          {new Date().getFullYear()} BLEEP, Anti-Counterfeit Hub Africa
        </p>
      </footer>
    </div>
  );
};

export default Footer;
