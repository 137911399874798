import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";

const CreateAccount = ({ domain, show, handleClose, handleFormSubmit }) => {
  const [isAdminSelected, setIsAdminSelected] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleTypeChange = (e) => {
    setIsAdminSelected(e.target.value === "a");
  };

  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    // Retrieve user permissions from cookies
    const userData = [
      Cookies.get("userId"),
      Cookies.get("userType"),
      Cookies.get("userName"),
      Cookies.get("userEmail"),
      Cookies.get("userPassword"),
      Cookies.get("userOrg"),
      Cookies.get("userPermissions"),
    ];

    const userId = userData[0];
    const userType = userData[1];
    const userName = userData[2];
    const userEmail = userData[3];
    const userPassword = userData[4];
    const userOrg = userData[5];
    const userPermissions = userData[6];

    // Define available options
    const availableOptions = [
      { value: "co", label: "Company/Brand owners/Manufacturers" },
      { value: "re", label: "Regulators" },
      { value: "a", label: "Admin" },
      { value: "sa", label: "Superadmin (Caution)" },
    ];

    if (userType != "superadmin" && userPermissions) {
      try {
        // Parse permissions string into an object
        const permissions = JSON.parse(userPermissions);
        console.log("Parsed permissions:", permissions);
        // Map permission keys to option values
        const optionMapping = {
          createCompanyUser: "co",
          createRegulatorUser: "re",
          adminuser: "a",
          superadminuser: "sa",
        };

        // Filter available options based on permissions
        const filteredOptions = availableOptions.filter((option) => {
          for (const key in permissions) {
            if (permissions[key] && optionMapping[key] === option.value) {
              console.log("Permission key:", key);
              console.log("Mapped value:", optionMapping[key]);
              return true;
            }
          }
          return false;
        });

        console.log("Filtered options:", filteredOptions);

        setFilteredOptions(filteredOptions);
      } catch (error) {
        console.error("Error parsing permissions:", error);
      }
    } else {
      setFilteredOptions(availableOptions);
      console.error("Permissions string not found");
    }
  }, []);

  const createUser = () => {
    const username = document.getElementById("username").value;
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;
    const organization = document.getElementById("organization").value;
    const type = document.getElementById("type").value;

    const permissions = {};
    if (type === "a") {
      // Check if admin type is selected
      permissions.createCompanyUser =
        document.getElementById("companyuser").checked;
      permissions.createRegulatorUser =
        document.getElementById("regulatoruser").checked;
      permissions.resolvePosts = document.getElementById("poststatus").checked;
      permissions.deletePosts = document.getElementById("deletepost").checked;
      permissions.deleteComments =
        document.getElementById("deletecomments").checked;
    }

    const userPermissions = JSON.stringify(permissions);

    const data = {
      username: username,
      email: email,
      password: password,
      organization: organization,
      type: type,
      permissions: userPermissions,
    };

    fetch("/api/users/admin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAdminSelected(false);
        handleClose();
        handleFormSubmit();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Create account</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            id="username"
            name="username"
            placeholder="Username"
          />
          <label htmlFor="username">User display name*</label>
        </div>

        <div className="form-floating mb-2">
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Email address"
          />
          <label htmlFor="email">
            Email address* <address></address>
          </label>
        </div>

        <div className="form-floating mb-2">
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            id="password"
            name="password"
            placeholder="Password"
          />
          <label htmlFor="password">Password*</label>
        </div>

        <div
          className="form-check mb-2"
          align="left"
        >
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={handleTogglePassword}
            />
            Show password
          </label>
        </div>

        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            id="organization"
            name="organization"
            placeholder="Organization"
          />
          <label htmlFor="organization">Organization*</label>
        </div>

        <select
          className="form-select mb-2"
          style={{ height: "60px" }}
          aria-label="Type"
          id="type"
          name="type"
          onChange={handleTypeChange}
        >
          <option value="">Select user type*</option>
          {filteredOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>

        {isAdminSelected && (
          <div>
            <b>This admin can</b>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="companyuser"
              />
              <label
                className="form-check-label"
                htmlFor="companyuser"
              >
                create company user
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="regulatoruser"
              />
              <label
                className="form-check-label"
                htmlFor="regulatoruser"
              >
                create regulators
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="poststatus"
              />
              <label
                className="form-check-label"
                htmlFor="poststatus"
              >
                mark posts as resolved
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="deletepost"
              />
              <label
                className="form-check-label"
                htmlFor="deletepost"
              >
                delete posts
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="deletecomments"
              />
              <label
                className="form-check-label"
                htmlFor="deletecomments"
              >
                delete comments
              </label>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => createUser()}
          className="btn btn-warning btn-lg"
        >
          Create <i className="fas fa-arrow-right"></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateAccount;
