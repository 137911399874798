import React, { useEffect, useState } from "react";
import CountryRibbon from "../components/CountryRibbon";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Downloads = ({ domain }) => {
  const trackEvent = (action) => {
    fetch("/api/trackers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        action: action,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Event tracked:", data);
      })
      .catch((error) => {
        console.error("Error tracking event:", error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Downloads | BLEEP</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          height: "100vh",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div className="row d-flex align-items-center">
          <div
            className="col"
            style={{ color: "white", padding: "20px" }}
          >
            <Link
              to="/"
              className="text-decoration-none text-white"
            >
              <b>
                <i className="fas fa-arrow-left"></i> Home
              </b>
              <br />
            </Link>
            <b
              style={{
                fontSize: "30px",
                lineHeight: "20px",
                textShadow: "#1c1c1c 1px 2px 0px",
              }}
            >
              Downloads
            </b>
            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              Be among the first to download the Bleep app now!
            </p>
            <a
              href="/downloads/bleep v1.2.2 Beta.apk"
              download={true}
            >
              <button
                type="button"
                className="btn btn-light btn-lg bleep-btn"
                onClick={() => {
                  trackEvent("Downloaded v1.2.2 Beta APK");
                }}
              >
                <span style={{ marginRight: "10px" }}>
                  <i className="fa-solid fa-download"></i>
                </span>
                <b>Download Bleep v1.2.2 (Android)</b>
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Downloads;
